import { type Delta, DeltaParser, HtmlParser } from '@avvoka/editor'
import {
  fromHtmlEntities,
  getObjectEntries,
  toHtmlEntities
} from '@avvoka/shared'

export const unsanitisedHtmlNode = (html: string) => {
  const node = HtmlParser.parse(html)

  for (const child of node[Symbol.iterator](true, true)) {
    if (child.isText()) {
      child.data = fromHtmlEntities(child.data)
    }

    if (child.isElement()) {
      getObjectEntries(child.attributes).forEach(([key, value]) => {
        child.attributes[key] = value
          .replace(/_Avvspace_aVV/g, ' ')
          .replace(/_Avvdot_aVV/g, '.')
      })
    }
  }
  return node
}

export const deltaToSanitisedHtml = (
  delta: Delta = EditorFactory.main.getDelta()
) => {
  const node = DeltaParser.parse(delta)

  const docxHtmlEntities = {
    60: '&lt;',
    62: '&gt;',
    34: '&quot;',
    38: '&amp;'
  }

  for (const child of node[Symbol.iterator](true, true)) {
    if (child.isText()) {
      child.data = toHtmlEntities(child.data, docxHtmlEntities, false)
    }

    if (child.isElement()) {
      getObjectEntries(child.attributes).forEach(([key, value]) => {
        if (typeof child.attributes[key] === 'string') {
          child.attributes[key] = value
            .replace(/ /g, '_Avvspace_aVV')
            .replace(/\./g, '_Avvdot_aVV')
        }
      })
    }
  }

  return node.toHTML(false, undefined, false)
}
